import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import statsOfTheCar from "../../assets/pages/home/statsOfTheCar.JPG";
import carRender from "../../assets/pages/home/profile_NEW.135.png";
import "../../styles/pageStyles/home/StatsOfTheCar.css";
import CardComponent from "../../components/CardComponent.jsx";

export default function StatsOfTheCar() {
  return (
    <Controller>
      <Scene>
        {(progress) => (
          <section id="statsOfTheCar">
            <div className="statsOfTheCar p-5 container row-12">
              <h1 className="text-white text-center">Stats of the car</h1>
              <div className="col-12 text-center">
                <img
                  className="rounded img-fluid"
                  src={carRender}
                  alt="statsOfTheCar Image"
                  style={{ maxWidth: "85%", margin: "0 auto" }}
                />
              </div>
              <div className="row row-cols-1 row-lg-cols-4 g-3 justify-content-center">
                <CardComponent title="Weighs:" text="66 kg" />
                <CardComponent title="Goes:" text="181km per kWh" />
                <CardComponent title="Drives:" text="Autonomously" />
                <CardComponent
                  title="Built by:"
                  text="Students at NTNU Trondheim"
                />
              </div>
            </div>
          </section>
        )}
      </Scene>
    </Controller>
  );
}

import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import jumpingWCar from "../../assets/pages/home/jumpingWCar.jpg";
import TotalEnergies from "../../assets/pages/Sponsors/colored/TOTALENERGIES.jpg";
import Zolve from "../../assets/pages/Sponsors/colored/Zolve.png";
import alva from "../../assets/pages/Sponsors/colored/Alva_industries.svg";
import SponsorCard from "../sponsors/sponsorCard";
import "../../styles/pageStyles/home/MainSponsors.css";

export default function MainSponsors() {
  return (
    <Controller>
      <Scene>
        {(progress) => (
          <section id="mainSponsors">
            <div className="mainSponsors p-5 container">
              <div className="row-12">
                <h1 className="p-5 text-light text-center">Main Sponsors</h1>
              </div>

              <div className="sponsorRow row">
                {sponsorsDiamond.map((sponsor, index) => (
                  <div
                    key={index}
                    className="pt-2 col-xl-4 col-md-6 col-12 d-flex justify-content-center align-self-center "
                  >
                    <SponsorCard
                      name={sponsor.name}
                      img={sponsor.img}
                      description={sponsor.description}
                      level="Diamond"
                      link={sponsor.link}
                    />
                  </div>
                ))}
              </div>
            </div>
          </section>
        )}
      </Scene>
    </Controller>
  );
}

const sponsorsDiamond = [
  {
    name: "Total Energies",
    img: TotalEnergies,
    description:
      "TotalEnergies is a broad energy company that produces and markets fuels, natural gas and electricity. The company has around 100,000 employees that are committed to better energy that is more affordable, more reliable, cleaner and accessible to as many people as possible. Active in more than 130 countries, their ambition is to become the responsible energy major.",
    link: "https://totalenergies.com/",
  },
  {
    name: "Alva",
    img: alva,
    description:
      "Alva was founded to meet the growing global energy demand with renewable technologies. We develop electric generators for production of clean energy and electric motors to convert this energy into motion.",
    link: "https://alvaindustries.com/",
  },
  {
    name: "Zolve",
    img: Zolve,
    description:
      "Zolve originates from Trondheims technological environment, and offers consulting services to a wide range of companies. Zolve is spesialists in electronics, FPGA and software, with over 60 years of experience, a core part of Zolve's success comes from openness and close communication between customer and developer, which are essential in order to achieve the best result.",
    link: "https://zolve.no/",
  },
];

import React, { useState, useEffect } from "react";

const Scroll = () => {
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    if (window.scrollY === 0) {
      // Show the button when scroll position matches the prop value
      setVisible(true);
    } else {
      // Hide the button when scrolling starts
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = () => {
    const screenHeight = window.innerHeight;
    const scrollAmount = screenHeight;
    window.scrollTo({ top: window.scrollY + scrollAmount, behavior: "smooth" });
  };

  return (
    visible && (
      <div
        className="scroll-to-bottom d-none d-xl-block"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "50%",
          transform: "translateX(50%)",
          cursor: "pointer",
          zIndex: "1000",
        }}
        onClick={handleClick}
      >
        <i
          className="bi bi-arrow-down-circle-fill"
          style={{ fontSize: "3rem", color: "#ffffff" }}
        ></i>
      </div>
    )
  );
};

export default Scroll;

import React from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import joinHero from "../assets/pages/join/joinHero.jpeg";
import "../styles/pageStyles/join.css";

import LeaderPositions from "./join/LeaderPositions";
import Title from "../components/Title";
// import GeneralPositions from "./join/GeneralPositions";

export const Join = () => {
  return (
    <section className="primaryColor">
      <Title title="Join us" image={joinHero} />
      <Container className="joinSection text-center primaryColor">
        <div className="introCard">
          Fuel Fighter NTNU is a multidisciplinary team that does everything
          from software engineering to product development. We need applicants
          from every field of study. Apply to enhance your education!
          <div className="deadLine">Deadline 28th August!</div>
          {/* <h2 className="text-center introCard" style={{fontFamily: 'Roboto-Black', fontSize: 'calc(var(--fontSize)*1.1)'}}>We are recruiting for new leader positions. Deadline: 19 March</h2> */}
          <div className="text-center mt-4">
            <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=cgahCS-CZ0SluluzdZZ8BSmf-_S3IvpJhmrFfv24f_xUNFRYQTE4UVNKSkNaSVdBSExLQ1A3MUowSi4u">
              <button className="btn btn-join primaryColor">Apply Now!</button>
            </a>
          </div>
        </div>
      </Container>
      <Container>
        <h3 className="text-center mt-5">Available Positions</h3>
        <LeaderPositions />
        {/* <GeneralPositions /> */}
      </Container>
    </section>
  );
};

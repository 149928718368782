import React, { useEffect } from "react";
import { Controller, Scene } from "react-scrollmagic";
import jumpingWCar from "../../assets/pages/home/jumpingWCar.jpg";
import "../../styles/pageStyles/home/MemberStats.css";

export default function MemberStats() {
  return (
    <Controller>
      <Scene>
        {(progress) => (
          <section id="memberStats">
            <div className="memberStats p-5 container">
              <div className="row">
                <div className="col-md-6 col-12">
                  <h1 className="text-light">Members across various fields</h1>
                  <p className="pt-3 large text-light">
                    Fighter NTNU is a cross-disciplinary organization. We have
                    members over 10 different fields of study. Among them are
                    electrical engineering, mechanical engineering, computer
                    science and cybernetics. Our members across subsystems
                    through design, production and testing to achieve the
                    maximum performance.
                  </p>
                </div>
                <div className="col-md-6 col-12">
                  <img
                    className="rounded img-fluid"
                    src={jumpingWCar}
                    alt="SEM image"
                  />
                </div>
              </div>
            </div>
          </section>
        )}
      </Scene>
    </Controller>
  );
}

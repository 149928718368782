import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import projectManager from "../../assets/pages/join/project_manager.JPG";
import assistant_project_manager from "../../assets/pages/join/assistant_project_manager.JPG";
import technicalLeader from "../../assets/pages/join/technical_leader.JPG";
import assistent_tech_lead from "../../assets/pages/join/assistent_tech_lead.JPG";
import head_of_finance from "../../assets/pages/join/head_of_finance.jpg";

import marketing_group_leader from "../../assets/pages/join/marketing_group_leader.JPG";
import autonomous_group_leader from "../../assets/pages/join/autonomous_group_leader.jpg";
import leader_design from "../../assets/pages/join/leader_design.jpg";
import groupL_electrical from "../../assets/pages/join/groupL_electrical.jpg";
import mech_leader from "../../assets/pages/join/mech_leader.jpg";
import software_leader from "../../assets/pages/join/software_leader.jpg";

import GeneralPositions from "./GeneralPositions";

const LeaderPositions = () => {
  const positions = leaderPositions;
  const [selectedPosition, setSelectedPosition] = useState(positions[0]);
  const [selectedRow, setSelectedRow] = useState("Board");

  const handleSelectPosition = (position) => {
    setSelectedPosition(position);
  };

  const handleSelectRow = (event) => {
    setSelectedRow(event.target.value);
    if (event.target.value === "Board") setSelectedPosition(positions[0]);
    if (event.target.value === "Group Leader")
      setSelectedPosition(positions[1]);
  };

  const firstRowPositions = positions.slice(0, 6);
  const secondRowPositions = positions.slice(6);

  return (
    <div className="positions-wrapper">
      <Container>
        <Row>
          <Col id="centeredRow">
            <Button
              className="button-positions"
              variant={selectedRow === "Board" ? "primary" : "secondary"}
              value="Board"
              onClick={handleSelectRow}
            >
              Board
            </Button>
            {/* <Button
              className="button-positions"
              variant={selectedRow === "Group Leader" ? "primary" : "secondary"}
              value="Group Leader"
              onClick={handleSelectRow}
            >
              Group Leaders
            </Button> */}
            <Button
              className="button-positions"
              variant={selectedRow === "General" ? "primary" : "secondary"}
              value="General"
              onClick={handleSelectRow}
            >
              General
            </Button>
          </Col>
        </Row>
        {selectedRow === "Board" && (
          <Row>
            {firstRowPositions.map((position) => (
              <Col
                key={position.title}
                className={`position-item ${
                  position === selectedPosition ? "selected" : ""
                }`}
                onClick={() => handleSelectPosition(position)}
              >
                {position.title}
              </Col>
            ))}
          </Row>
        )}
        {selectedRow === "Group Leader" && (
          <Row>
            {secondRowPositions.map((position) => (
              <Col
                key={position.title}
                className={`position-item ${
                  position === selectedPosition ? "selected" : ""
                }`}
                onClick={() => handleSelectPosition(position)}
              >
                {position.title}
              </Col>
            ))}
          </Row>
        )}
        {(selectedRow === "Group Leader" || selectedRow === "Board") && (
          <Row
            className="selected-position align-items-start"
            style={{ flexDirection: "row", marginTop: "2rem" }}
          >
            <h3 style={{ textAlign: "left" }}>{selectedPosition.title}</h3>
            <Col xs={12} sm={5}>
              <img
                src={selectedPosition.image}
                alt={selectedPosition.title}
                className="position-image"
              />
            </Col>
            <Col xs={12} sm={7} id="PosLeadDescription">
              <p style={{ textAlign: "left" }}>
                {selectedPosition.description}
              </p>
              <h5 style={{ textAlign: "left" }}>
                What you will learn from this position:
              </h5>
              <ul style={{ textAlign: "left", paddingLeft: "20px" }}>
                {selectedPosition.learn
                  .split(".")
                  .slice(0, -1)
                  .map((sentence, index) => (
                    <li key={index}>{sentence.trim()}</li>
                  ))}
              </ul>
            </Col>
          </Row>
        )}
        {selectedRow === "General" && <GeneralPositions />}
      </Container>
    </div>
  );
};

export default LeaderPositions;

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LEADER RECRUITMENT                                                                                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const leaderPositions = [
  {
    title: "Project Manager",
    description:
      "The project manager is responsible for the overall success of the project and leads the team to Shell Eco-marathon. Day to day, you follow up the project, ensure communication across groups and lead meetings and strategic decisions for Fuel Fighter NTNU. You will also be the face of the organization, and work with external stakeholders. The project manager is also responsible for delegating tasks so that the budget is kept, the technical group progresses, and that the team has a good working environment.",
    learn:
      "Get real leadership experience as a student.Learn about the practical aspects of managing a project.Collaborate with different actors, from fellow student organizations, sponsors and NTNU.",
    image: projectManager,
  },
  {
    title: "Technical Leader",
    description:
      "The technical leader is responsible for the technical groups of Fuel Fighter NTNU, ensuring that we have a rules-compliant and working car at competition. Day to day, you coordinate with group leaders, arrange testing, arrange peer reviews, and follow up the design, production, and testing work. You are the closest leader to the technical group leaders and will have responsibility for following up the leaders and members of each group together with the assistant technical leader.",
    image: technicalLeader,
    learn:
      "Get real leadership experience as a student.Learn about engineering in a cross-disciplinary team.Insight into automotive engineering, and energy efficiency.",
  },
  {
    title: "Assistant Technical Leader",
    image: assistent_tech_lead,
    description:
      "The assistant technical leader is the right hand of the technical leader of Fuel Fighter NTNU, in the task of ensuring that we have a rules-compliant and working car at competition. Day to day, you coordinate with group leaders, arrange production, keep track of progress, and follow up the design, production, and testing work along with the technical leader. You will have a position on the board and participate in strategic decision making and have a large influence on our development and design.",
    learn:
      "Get real leadership experience as a student.Learn about engineering in a cross-disciplinary team.Insight into automotive engineering, and energy efficiency.",
  },
];

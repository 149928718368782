import React from "react";
import "../../styles/pageStyles/home/CountDown.css";

export default function ApplyButton(props) {
  const { text, link } = props; // Destructure props to get text
  return (
    <div className="card custom-card">
      <h2 className="fs-5 pt-3">{text}</h2>
      <a className="button-suggested-action" href={link}>
        <button
          type="button"
          className="btn bg-transparent btn-outline-light"
          style={{ borderColor: "white" }}
        >
          Apply now
        </button>
      </a>
    </div>
  );
}

import React from "react";

export default function CardComponent(CardText) {
  return (
    <div className="col-lg-3 col-12 text-center mb-3">
      <div className="card text-white bg-dark w-100 h-100 mx-auto">
        <div className="card-body">
          <h3 className="text-center fw-bold">{CardText.title}</h3>
          <p className="text-center fw-bold">{CardText.text}</p>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import styled, { keyframes } from "styled-components";
import { Controller, Scene } from "react-scrollmagic";
import "../styles/pageStyles/history/history.css";
import SectionModule from "../components/history/SectionModule";
import HistorySection from "./history/HistorySection";
import Timeline from "./history/Timeline";
import Title from "../components/Title.jsx";
import bannerImage from "../assets/pages/history/HistoryBackgroundImage.JPG";

const years = [
  2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010,
  2009, 2008,
];

export default function History() {
  return (
    <section className="historyContainer">
      {/* <div className="Years p-1 fixed-bottom">
        <div className="row-6 pb-5">
          {years.map((year, index) => (
            <div className="col-6 ps-5" key={index}>
              <p1 className="fs-3">{year}</p1>
            </div>
          ))}
        </div>
      </div> */}
      <Title title="History Of Fuel Fighter NTNU" image={bannerImage} />
      <HistorySection />
    </section>
  );
}

import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSpring, animated } from "react-spring";
import PartnerShipCard from "./PartnerShipCard";

import "../../styles/pageStyles/Sponsors/card.css";

const AnimatedCard = animated(Card);
const levels = {
  Diamond: {
    cardStyle: "diamondSponsor",
    cardSize: "500px",
    medal: <div className="medal diamond">💎</div>,
    titleSpan: <span className="diamond">Platinum Sponsor</span>,
  },
};

export default function SponsorCardPlatinum({ sponsors, sponsorList, level }) {
  const getCardSize = () => levels[level]?.cardSize || "100px";
  const [hoveredSponsor, setHoveredSponsor] = useState(null);

  const animationProps = useSpring({
    opacity: 1,
    transform: "translateY(0)",
  });

  const getMedal = () => levels[level]?.medal || null;

  return (
    <section className="DisplayPlatinumSponsors">
      <div className="sponsorDiv text-white pt-5 row-12">
        <h3>{sponsors}</h3>
        <Row className="sponsorRow pt-4 ">
          {sponsorList.map((sponsor) => (
            <Col key={sponsor.name} xs={12} md={6} lg={4} className="pb-5">
              <a href={sponsor.link} style={{ textDecoration: "none" }}>
                <AnimatedCard
                  onMouseEnter={() => setHoveredSponsor(sponsor.name)}
                  onMouseLeave={() => setHoveredSponsor(null)}
                  style={{
                    ...animationProps,
                    padding: "1%",
                    height: `${getCardSize()}`,
                    width: "100%",
                    border:
                      hoveredSponsor === sponsor.name
                        ? "15px solid #b9f2ff"
                        : "15px solid white",
                  }}
                  className={`col${levels[level]?.cardStyle + ""}`}
                  aria-label={`${sponsor.name} sponsor card`}
                >
                  {getMedal()}
                  <Card.Img
                    src={sponsor.img}
                    alt={`${sponsor.name} img`}
                    aria-label={`${sponsor.name} image`}
                    style={{ maxWidth: "80%", margin: "0 auto" }}
                  />
                  <Card.Body>
                    <Card.Text style={{ color: "black" }}>
                      {sponsor.description}
                    </Card.Text>
                  </Card.Body>
                </AnimatedCard>
              </a>
            </Col>
          ))}
        </Row>
      </div>
      <PartnerShipCard />
    </section>
  );
}

import { fetch } from "whatwg-fetch";

import variables from "../shared/variables";

const url = `${variables.serverUrl}/backend/team/`;

export default class teamService {
    static async team(year, number) {
        return fetch(
            `${url}team.php?&number=${number}${
                year !== null ? "&year=" + year : ""
            }`
        ).then((data) => data.json());
    }

    static async allYears() {
        return fetch(`${url}all_years.php`).then((data) => data.json());
    }

    static async getGroups(year) {
        return fetch(`${url}group_list.php?&year=${year}`).then((data) =>
            data.json()
        );
    }

    static async getGroupMembers(year, group) {
        return fetch(`${url}get_members.php?group=${group}&year=${year}`).then(
            (data) => data.json()
        );
    }

    static async getDescription(year, group) {
        return fetch(
            `${url}get_description.php?group=${group}&year=${year}`
        ).then((data) => data.json());
    }
}

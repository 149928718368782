import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import Countdown from "react-countdown";
import "../../styles/pageStyles/home/CountDown.css";

export default function CountDown() {
  const endTime = new Date(2025, 5, 10).getTime();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="countdown row-12">
        <div className="countdown-item col-3">
          <h2 className="countdown-value digital-clock">{days}</h2>
          <span className="countdown-label">Days</span>
        </div>
        <div className="countdown-item col-3">
          <h2 className="countdown-value digital-clock">{hours}</h2>
          <span className="countdown-label">Hours</span>
        </div>
        <div className="countdown-item col-3">
          <h2 className="countdown-value digital-clock">{minutes}</h2>
          <span className="countdown-label">Mins</span>
        </div>
        <div className="countdown-item col-3">
          <h2 className="countdown-value digital-clock">{seconds}</h2>
          <span className="countdown-label">Secs</span>
        </div>
      </div>
    );
  };

  return (
    <Controller>
      <Scene>
        {(progress) => (
          <section id="countDown">
            <div className="d-flex fixed-left justify-content-center countDown m-2 pt-5">
              <div className="card custom-card">
                <h1 className="text-center h5 pt-3">Time until competition:</h1>
                <div className="countdown-item card-body">
                  <Countdown
                    date={endTime}
                    renderer={renderer}
                    zeroPadTime={2}
                    precision={3}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
      </Scene>
    </Controller>
  );
}

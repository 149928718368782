import React, { useState, useEffect, useRef } from "react";
import CountDown from "./home/CountDown";
import "../styles/pageStyles/home/home.css";
import SEMInfo from "./home/SEMInfo";
import StatsOfTheCar from "./home/StatsOfTheCar";
import ApplyButton from "./home/ApplyButton.jsx";
import MemberStats from "./home/MemberStats";
import MainSponsors from "./home/MainSponsors";
import mainPage from "../assets/pages/home/mainPage.JPG";
import Title from "../components/Title.jsx";
import PartnerWithUs from "./home/PartnerWithUs.jsx";

export default function Home() {
  const [showCountdown, setShowCountdown] = useState(true);
  const mainImageRef = useRef(null);
  const screenWidthThreshold = 1080;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      // Define a function to scale the threshold based on the screen dimensions
      const calculateThreshold = (height, width) => {
        // Adjust the factors as needed to fine-tune the threshold
        const heightFactor = 0.25; // Change this factor based on your preference for height
        const widthFactor = 0.25; // Change this factor based on your preference for width
        return height * heightFactor + width * widthFactor;
      };

      // Calculate the threshold based on the screen dimensions
      const threshold = calculateThreshold(windowHeight, windowWidth);

      if (scrollPosition > threshold && windowWidth > screenWidthThreshold) {
        setShowCountdown(false);
      } else {
        setShowCountdown(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="homeContainer" style={{ position: "relative" }}>
      <div className="applyField d-none d-lg-block">
        <div
          className="applyText position-fixed backgroundcolor m-2 text-center"
          style={{ bottom: "0px", right: "0px", zIndex: 999 }}
        >
          <ApplyButton text="Apply for Fuel Fighter NTNU!" link="./join" />
        </div>
      </div>

      <div className="applyField d-none d-lg-block">
        <div
          className="applyText position-fixed backgroundcolor m-2 text-center"
          style={{ bottom: "120px", right: "0px", zIndex: 999 }}
        >
          <ApplyButton
            text="Apply for Specialization Project & Master Thesis"
            link="https://forms.office.com/e/ccsFGAp5rp"
          />
        </div>
      </div>

      <Title title="Fuel Fighter NTNU" image={mainPage} />
      <div ref={mainImageRef}>{showCountdown && <CountDown />}</div>
      <SEMInfo />
      <StatsOfTheCar />
      <MemberStats />
      <MainSponsors />
      {/* <PartnerWithUs /> */}
    </div>
  );
}

import React from "react";
import SponsorCard from "./sponsorCard";
import PartnerShipCard from "./PartnerShipCard";

export default function DisplaySponsors(props) {
  const { sponsors, sponsorList, level } = props;
  return (
    <section className="DisplaySponsors">
      <div class="sponsorDiv text-white pt-5 pb-5">
        <h3>{sponsors}</h3>
        <div className="sponsorRow row-12">
          {sponsorList.map((sponsor) => (
            <SponsorCard
              key={sponsor.name}
              name={sponsor.name}
              img={sponsor.img}
              description={sponsor.description}
              level={level}
              link={sponsor.link}
            />
          ))}
        </div>
        <PartnerShipCard />
      </div>
    </section>
  );
}

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Profile from "../Team/MemberProfile";

import getMemberDetails from "../../_services/leader.service.js";

import electricImg from "../../assets/pages/join/software_general.jpg";
import machanicImg from "../../assets/pages/join/mechanical.JPG";
import marketinImg from "../../assets/pages/join/marketing_and_finance.JPG";
import autonomoImg from "../../assets/pages/join/autonomous_description.JPG";
import design_description from "../../assets/pages/join/design_description.jpg";
import software_description from "../../assets/pages/join/electrical.JPG";

const generalPositions = [
  {
    title: "Electrical Group",
    subtitle: `The electrical group is responsible for ensuring that the electrical
      components in the car function properly and comply with the SEM
      technical requirements. Their responsibilities include PCB design,
      programming MCU cards for motor control, and communication between
      different subsystems within the whole electrical system.`,
    description: (
      <div id="descriptionGenPosition">
        <ul>
          <li>
            <b>Motor Control:</b>
            <br />
            This task involves design and testing of both the MCU control card,
            and the H-Bridge stage connected directly to the motor. This task is
            important to ensure the car can complete the SEM competition and do
            so efficiently. This means rigorous testing, troubleshooting, and
            going through multiple revisions of all the components of this
            subsystem to find improvements to the design.
          </li>
          <li>
            <b>Power Distribution:</b>
            <br />
            The power distribution system ensures that all components receive
            enough power/current. Many of the components operate at different
            voltages so the power distribution system needs to provide enough
            current to all these different components. This subsystem needs to
            be tested and potentially adapted to depending on what changes are
            made to other electrical components.
          </li>
          <li>
            <b>BMS:</b>
            <br />
            The BMS (Battery management system) ensures that the battery does
            not drop below the safe operating voltage range and does not
            overheat. This system will disconnect the battery if any of these
            problems arise. This system is important for the safety of the car.
            Working on this subsystem you will be responsible for ensuring that
            the BMS passes inspection and that it is designed specifically for
            the battery that is being used in the vehicle.
          </li>
          <li>
            <b>Wire Harness:</b>
            <br />
            This task involves managing all the connections between electrical
            components in the car, and placement and organization of these
            components. The components should be placed in such a way that is
            efficient and organized with efficient cable management. Some of the
            components and wires will need additional housing and shielding to
            reduce EMI.
          </li>
        </ul>
        <div className="h4">Who can join?</div>
        <p>
          The best part is that anyone can be a part of our Electrical Group -
          no specific knowledge or experience required! All we ask for is a
          strong willingness to learn. We believe that with dedication and
          curiosity, anyone can excel in our team and contribute to building an
          amazing project. Whether you have experience or not, the Electrical
          Group will provide you with opportunities to put theoretical knowledge
          into practice, acquire essential electrical engineering skills like
          soldering and programming, and challenge yourself with complex
          electrical systems.
        </p>
        <div className="h4">Prerequisite?</div>
        <p>
          If you are interested in electronics and want to get your hands on a
          unique project you are more than welcome to apply.<br></br>
          The electrical members are typically students in electrical systems
          design and innovation or similar.
        </p>
      </div>
    ),
    // leader: 190,
    image: electricImg,
  },
  {
    title: "Chassis Group",
    subtitle: `The chassis group is responsible for making the chassis of our car this year, as well as designing and manufacturing the aeroframe that will ensure the highest efficiency. 
    The chassis group needs to coordinate with the rest of the team to design a maintainable, light and strong chassis. 
    In addition, the group is responsible for the driver interface, designing the seat, steering wheel and pedalbox. `,
    description: (
      <div id="descriptionGenPosition">
        <ul>
          <li>
            <b>Group leader:</b>
            <br />
            The Group leader for Design will be responsible for leading the
            design process of the chassis and driver interface for our next car.
            You will ensure that there is space for mechanical and electrical
            parts, and that our next car is as aerodynamic and light as possible
            while still being able to perform in competition.
          </li>
          <li>
            <b>Chassis CAD:</b>
            <br />
            This role will be responsible for designing the entire chassis of
            the new car. You will need to coordinate with other systems and
            ensure the chassis will meet the performance standard that is
            required for it to be as efficient as possible.
          </li>
          <li>
            <b>Chassis production:</b>
            <br />
            This role works with chassis CAD and plans the manufacture of the
            chassis. You will coordinate with other groups and companies to
            ensure that deadlines are met, and that Fuel Fighter has the
            equipment it needs. This is a highly practical role that requires
            understanding of mechanical design, as well as an interest for
            manufacturing.
          </li>
          <li>
            <b>Aeroframe design & simulation:</b>
            <br />
            This role works on the aeroframe of the car, designing and
            simulating the outer shell of the car to ensure optimal aerodynamics
            for efficiency. You will design the geometry, validate simulations
            and ensure that the design is rules compliant.
          </li>
          <li>
            <b>Aeroframe production:</b>
            <br />
            This role works on the aeroframe of the car with design &
            simulation. Your role is to make carbon fiber layups, as well as
            plan the production of the aeroframe itself. You will design moulds,
            coordinate production of the moulds and with your group, manufacture
            the aeroframe itself.
          </li>
        </ul>
      </div>
    ),
    image: design_description,
    leader: 202,
  },
  {
    title: "Mechanical Group",
    subtitle: `The mechanical group is responsible for all moving mechanical components in the car. 
      This includes suspension, brakes, steering and the drivetrain itself. 
      The group works with the rest of the team to ensure that the car is as efficient and reliable as possible.`,
    description: (
      <div id="descriptionGenPosition">
        <ul>
          <li>
            <b>Group leader:</b>
            <br />
            The group leader is responsible for the mechanical part of the car,
            and cross-coordination with the chassis and electrical groups to
            ensure reliability and that deadlines are met.
          </li>
          <li>
            <b>Steering:</b>
            <br />
            This role works on designing and choosing parts for the steering
            system on our car. The system needs to be lightweight, strong and
            comfortable for the driver. In this role, you will also work with
            the electrical group on the mechanical implementation of autonomous
            steering for future seasons.
          </li>
          <li>
            <b>Brakes:</b>
            <br />
            This role is responsible for the brakes of the car. This includes
            selecting brake discs, calipers and installation method. In
            addition, you will need to document the process, as well as get the
            car ready for testing during spring.
          </li>
          <li>
            <b>Drivetrain & upright:</b>
            <br />
            This role works on the upright of the vehicle, and installation of
            the motor which translates rotational energy to the wheels. You will
            design and ensure that the design is manufacturable, as well as
            validate the strength of the part all while ensuring that it is
            lightweight.
          </li>
          <li>
            <b>Suspension:</b>
            <br />
            The suspension of the vehicle ensures optimal performance on track,
            and for other surfaces that we would like to drive on. In order to
            distribute the load in an optimal way, and to ensure that the
            vehicle acts like we want to, the suspension design is central. This
            role will cover the design, manufacture and installation of the
            suspension system in the car, working tightly with the drivetrain
            and upright responsible
          </li>
        </ul>
        <div className="h4">Who can join?</div>
        <p>
          A mechanical member is typically someone who studies mechanical
          engineering or something similar. If you have an interest in working
          with the mechanical team without this backround, we as always value
          enthusiasm before knowledge.
        </p>
      </div>
    ),
    image: machanicImg,
  },
  {
    title: "Marketing Group",
    subtitle: `The marketing team is dedicated to promoting our brand. 
		The team collaborates to create and execute comprehensive marketing strategies that drive awareness, and engagement. 
		Comprising diverse roles, the team ensures a cohesive approach to achieving the organization’s marketing goals.`,
    image: marketinImg,
    description: (
      <div id="descriptionGenPosition">
        <ul>
          <li>
            <b>Social Media:</b>
            <br />
            As a social media responsible, you will manage and create content
            for our social media platforms. You will develop strategies to
            increase engagement, grow our online presence, and analyze social
            media activities. Collaboration with the marketing team to align
            social media efforts with overall marketing goals is essential.
          </li>
          <li>
            <b>Photo & Video:</b>
            <br />
            The photo and video responsible is responsible for creating
            high-quality visual content that supports our brand and marketing
            initiatives. You will handle photography, videography, and editing,
            ensuring all visuals align with our brand identity and messaging.
          </li>
          <li>
            <b>Key Account Manager:</b>
            <br />
            As a key account manager, you will be the primary point of contact
            for our sponsors. You will build and maintain strong relationships
            with them. Your goal is to achieve long-term partnerships.
          </li>
          <li>
            <b>Graphic Designer:</b>
            <br />
            The graphic designer will create visually appealing designs that
            communicate our brand message effectively. You will work on a
            variety of projects including digital and print media, ensuring
            consistency across all platforms.
          </li>
        </ul>
        <div className="h4">Who can join?</div>
        <p>
          This is probably the most diverse group in Fuel Fighter, as the most
          important characteristic we look for is an interest in marketing,
          sponsorships, and/or engaging with students.
        </p>
        <div className="h4">For more information, please contact:</div>
        <p>
          Olav Narjord, project manager{" "}
          <a href="mailto:leder@fuelfighter.no">leder@fuelfighter.no</a>
        </p>
      </div>
    ),
  },
];

const GeneralPositions = () => {
  const [selectedPosition, setSelectedPosition] = useState(generalPositions[0]);
  const [leader, setLeader] = useState([]);

  const handleSelectPosition = (position) => {
    setSelectedPosition(position);
  };

  useEffect(() => {
    getMemberDetails(selectedPosition.leader).then((res) => {
      setLeader(Object.values(res));
    });
  }, [selectedPosition]);

  return (
    <div className="positions-wrapper">
      <Container>
        <Row>
          {generalPositions.map((position) => (
            <Col
              key={position.title}
              className={`position-item ${
                position === selectedPosition ? "selected" : ""
              }`}
              onClick={() => handleSelectPosition(position)}
            >
              {position.title}
            </Col>
          ))}
        </Row>
        <Row
          className="selected-position align-items-start"
          style={{ flexDirection: "row", marginTop: "2rem" }}
        >
          <div class="mb-3">
            <h3 style={{ textAlign: "left" }} className="">
              {selectedPosition.title}
            </h3>
            <h5 style={{ textAlign: "left" }}>{selectedPosition.subtitle}</h5>
          </div>
          <Col
            xs={12}
            sm={5}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img
              src={selectedPosition.image}
              alt={selectedPosition.title}
              className="position-image"
            />
          </Col>
          <Col xs={12} sm={7} id="PosLeadDescription">
            <ul>{selectedPosition.description}</ul>
          </Col>
        </Row>
        {/* <Row>
                    <Col xs={12} sm={6} style={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div id='descriptionGenPosition'>
                            <div className="h4">What to know more?</div>
                            { (leader.length ===6) && (<p>Talk to the {selectedPosition.title} Team Leader team 23, {leader[0].trim()}.</p>)}
                        </div>
                    </Col>
                    <Col xs={12} sm={6} style={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        { (leader.length === 6) && ( <Profile name={leader[0].trim() + ' ' + leader[1].trim()} study={leader[2]} mail={leader[3]} image={leader[4]} position={`Team Leader ${selectedPosition.title}`} linkedin={leader[5]} year={'2023'} />)}
                    </Col>
                </Row> */}
      </Container>
    </div>
  );
};

export default GeneralPositions;

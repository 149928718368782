import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import "../../styles/pageStyles/Sponsors/PartnerShipCard.css";

export default function PartnerShipCard() {
  const [isHovered, setIsHovered] = useState(null);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const animationProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? "translateY(0)" : "translateY(100px)",
  });

  return (
    <section className="PartnerShipCard d-flex justify-content-center pt-5">
      <a
        href="/contact"
        className="card-link"
        style={{ textDecoration: "none" }}
      >
        <animated.div
          className="card-container pt-5"
          ref={ref}
          style={animationProps}
        >
          <div
            className="card text-black text-center"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
              border: isHovered ? "15px solid gray" : "15px solid white", // Change background color on hover
            }}
          >
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-center">
                <h1 className="card-title">Interested in a partnership?</h1>
                <i
                  className="bi bi-arrow-right-circle-fill"
                  style={{ fontSize: "3rem", color: "#000000" }}
                ></i>
              </div>
            </div>
          </div>
        </animated.div>
      </a>
    </section>
  );
}

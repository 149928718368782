import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import "../styles/componentStyles/Title.css";
import Scroll from "./Scroll";
export default function Title(props) {
  return (
    <Controller>
      <Scene duration={600} triggerHook="onLeave">
        {(progress) => (
          <section id="Title" style={{ opacity: 1 - progress }}>
            <div className="Title bg-image text-center position-relative">
              <img
                src={props.image}
                alt="bannerImage"
                className="img-fluid"
                style={{
                  width: "100%", // Default width for smaller screens
                  height: "auto", // Default height for smaller screens
                }}
              />

              <div class="position-absolute top-50 start-50 translate-middle">
                <h1
                  className="display-1 text-white d-none d-md-block"
                  style={{ textShadow: "3px 3px 3px black" }}
                >
                  {props.title}
                </h1>
                <h1
                  className="display-1 text-white d-block d-md-none"
                  style={{ textShadow: "3px 3px 3px black" }}
                >
                  {props.title}
                </h1>
              </div>
            </div>
            <Scroll />
          </section>
        )}
      </Scene>
    </Controller>
  );
}
